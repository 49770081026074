import { graphql } from "gatsby";
import parse from "html-react-parser";
import React from "react";
import "../css/@wordpress/block-library/build-style/style.css";
import "../css/@wordpress/block-library/build-style/theme.css";
import * as s from "../styles/blog-author.module.css";
import Layout from "./../components/layout";
import News from "./../components/news/news";
import Seo from "./../components/seo";
import AuthorBio from './../components/author/author';

const BlogAuthorTemplate = ({ data: { author, posts }, location }) => {
  let newsBlock = {
    title: <span className="text-center d-block">Publications</span>,
    items: posts,
    useAlterPost: true,
    hideSlider: true
  }
  if (!posts?.nodes?.length) {
    newsBlock.title = <span className="text-center d-block">What to read next</span>
  }

  let avatarUrl = author.avatar.url.replace(`s=${author.avatar.size}`, "s=256");

  return (
    <Layout>
      <Seo title={author.name} description={author.description} />

      <article className="blog-post">
        <section className={s.heading}>
          <div className={s.headingBg}></div>
          <div className="container">
            <div className={`d-flex align-items-start ${s.headingInner}`}>
              <img src={avatarUrl} alt={author.name} className={s.authorImg} />
              <div>
                <h1>{parse(author.name)}</h1>
                {/* <>
                  {
                    (!!author.seo.social.facebook
                    || !!author.seo.social.twitter
                    || !!author.seo.social.linkedIn)
                    && <>
                      <div className={`mt-4 mt-md-0 ${s.authorSocials}`}>
                        {!!author.seo.social.facebook &&
                          <a href={author.seo.social.facebook} target="_blank" rel="noreferrer">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.0001 0.041626C4.47724 0.041626 0 4.51886 0 10.0417C0 14.995 3.60522 19.0972 8.33225 19.8916V12.128H5.91994V9.33417H8.33225V7.27413C8.33225 4.88389 9.79213 3.58135 11.9247 3.58135C12.9461 3.58135 13.8238 3.65746 14.0786 3.69098V6.18942L12.5995 6.19013C11.44 6.19013 11.2165 6.74102 11.2165 7.54969V9.33274H13.9832L13.6223 12.1265H11.2165V19.9584C16.1642 19.3562 20 15.1495 20 10.0388C20 4.51886 15.5228 0.041626 10.0001 0.041626Z" fill="#010002" />
                            </svg>
                          </a>
                        }
                        {!!author.seo.social.twitter &&
                          <a href={`https://twitter.com/${author.seo.social.twitter}`} target="_blank" rel="noreferrer">
                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M19.6169 0.00896646C18.763 0.611275 17.8176 1.07195 16.8171 1.37324C16.28 0.755757 15.5663 0.318104 14.7725 0.119469C13.9786 -0.0791667 13.1429 -0.0292013 12.3784 0.262607C11.6138 0.554416 10.9573 1.07399 10.4977 1.75105C10.0381 2.42812 9.7975 3.23001 9.80847 4.04827V4.93995C8.24146 4.98058 6.68873 4.63305 5.28855 3.92829C3.88838 3.22354 2.68424 2.18345 1.78336 0.900645C1.78336 0.900645 -1.78336 8.92575 6.24175 12.4925C4.40536 13.739 2.21775 14.364 0 14.2758C8.02511 18.7342 17.8336 14.2758 17.8336 4.02152C17.8328 3.77315 17.8089 3.52539 17.7622 3.28143C18.6723 2.38395 19.3145 1.25082 19.6169 0.00896646Z" fill="black" />
                            </svg>
                          </a>
                        }
                        {!!author.seo.social.linkedIn &&
                          <a href={author.seo.social.linkedIn} target="_blank" rel="noreferrer">
                            <svg width="20" height="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                              <g id="XMLID_1_">
                                <path id="XMLID_5_" fill="#000000" d="M256,0C114.5,0,0,114.5,0,256s114.5,256,256,256s256-114.5,256-256S397.5,0,256,0z M398.4,380.7 c0,11.2-9.3,20.5-21.4,20.5h-243c-12.1,0-21.4-9.3-21.4-20.5V134.1c0-11.2,9.3-20.5,21.4-20.5h243c12.1,0,21.4,9.3,21.4,20.5V380.7 L398.4,380.7z" />
                                <path id="XMLID_6_" fill="#000000" d="M305.3,221.6c-23.3,0-33.5,13-39.1,21.4l0,0l0,0l0,0v-18.6h-42.8c0.9,12.1,0,129.4,0,129.4h42.8 v-72.6c0-3.7,0-7.4,1.9-10.2c2.8-7.4,10.2-15.8,22.3-15.8c15.8,0,21.4,12.1,21.4,29.8v69.8h42.8v-74.5 C354.7,240.2,334.2,221.6,305.3,221.6z" />
                                <path id="XMLID_7_" fill="#000000" d="M177.8,162c-14.9,0-24.2,9.3-24.2,22.3c0,12.1,9.3,22.3,24.2,22.3l0,0 c14.9,0,24.2-10.2,24.2-22.3C202,171.3,192.7,162,177.8,162z" />
                                <rect id="XMLID_8_" x="156.4" y="224.3" width="42.8" height="129.4" />
                              </g>
                            </svg>
                          </a>
                        }
                      </div>
                    </>
                  }
                </> */}
              </div>
            </div>
          </div>
        </section>
        <section className={s.about}>
          <div className="container">
            <h3>ABOUT</h3>
            <p>{author.description}</p>
          </div>
        </section>

        {/* <section>
          <div className="container">
            <div className={`${s.postFooter}`}>
              <div className={s.shareBlock}>
                <h4 className="d-block d-md-none">Share: </h4>
                <div className={s.socials}>
                  <a
                    className=""
                    href={`https://www.facebook.com/sharer.php?u=${location.href
                      }&t=${parse(author.name)}`}
                    target="_blank"
                    rel="noreferrer"
                    data-original-title="Facebook"
                  >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.0001 0.041626C4.47724 0.041626 0 4.51886 0 10.0417C0 14.995 3.60522 19.0972 8.33225 19.8916V12.128H5.91994V9.33417H8.33225V7.27413C8.33225 4.88389 9.79213 3.58135 11.9247 3.58135C12.9461 3.58135 13.8238 3.65746 14.0786 3.69098V6.18942L12.5995 6.19013C11.44 6.19013 11.2165 6.74102 11.2165 7.54969V9.33274H13.9832L13.6223 12.1265H11.2165V19.9584C16.1642 19.3562 20 15.1495 20 10.0388C20 4.51886 15.5228 0.041626 10.0001 0.041626Z" fill="#010002" />
                    </svg>
                    <span className="d-none d-md-block">Share on Facebook</span>
                  </a>
                  <a
                    className=""
                    href={`https://twitter.com/share?url=${location.href
                      }&text=${parse(author.name)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-original-title="Twitter"
                  >
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.6169 0.00896646C18.763 0.611275 17.8176 1.07195 16.8171 1.37324C16.28 0.755757 15.5663 0.318104 14.7725 0.119469C13.9786 -0.0791667 13.1429 -0.0292013 12.3784 0.262607C11.6138 0.554416 10.9573 1.07399 10.4977 1.75105C10.0381 2.42812 9.7975 3.23001 9.80847 4.04827V4.93995C8.24146 4.98058 6.68873 4.63305 5.28855 3.92829C3.88838 3.22354 2.68424 2.18345 1.78336 0.900645C1.78336 0.900645 -1.78336 8.92575 6.24175 12.4925C4.40536 13.739 2.21775 14.364 0 14.2758C8.02511 18.7342 17.8336 14.2758 17.8336 4.02152C17.8328 3.77315 17.8089 3.52539 17.7622 3.28143C18.6723 2.38395 19.3145 1.25082 19.6169 0.00896646Z" fill="black" />
                    </svg>
                    <span className="d-none d-md-block">Share on Twitter</span>
                  </a>
                </div>
              </div>
              <AuthorBio author={author} />
            </div>
          </div>
        </section> */}

        <section className={s.postLine}></section>
        <News {...newsBlock} />
      </article>
    </Layout>
  );
};

export default BlogAuthorTemplate;

export const pageQuery = graphql`
  query BlogAuthorById(
    $id: String!
    $authorId: Int!
  ) {
    author: wpUser(id: {eq: $id}) {
      avatar {
        size
        url
      }
      name
      description
      email
      slug
      uri
      seo {
        social {
          facebook
          linkedIn
          twitter
          wikipedia
        }
      }
    }
    posts: allWpPost(
      sort: {fields: [date], order: DESC}
      filter: {authorDatabaseId: {eq: $authorId}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`;
